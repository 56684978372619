.done-icon {
    font-size: 5rem; /* Increase the size of the icon */
    color: #009ccf;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pi.pi-check {
    font-size: inherit; /* Inherit the size from the parent */
    animation: scaleIn 0.5s ease-in-out forwards, rotateIn 0.5s ease-in-out forwards;
}

@keyframes scaleIn {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes rotateIn {
    0% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
