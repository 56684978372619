.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #93DBD6 50%, #48b9ff 50%);
  }
  
  .not-found-content {
    text-align: center;
  }
  
  .not-found-image {
    max-width: 100%;
    height: auto;
    margin-left: 3.8%;
  }
  
  .not-found-text {
    font-size: 1.5em;
    color: #333;
  }
  
  .home-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .home-button:hover {
    background-color: #0056b3;
  }
  