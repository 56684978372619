.SelectVersion .p-dropdown-label {
  color: black;
  font-size: 14px;
  font-weight: 400;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 0;
  max-width: 300px;
  white-space: normal;
  overflow-wrap: break-word;
  display: block;
  font-family: sans-serif;
}
.dialog .p-tabview-nav {
  height: 0;
}
.p-dropdown-panel {
  max-width: 300px;
}
.p-dropdown-item {
  white-space: normal;
  overflow-wrap: break-word;
  display: block;
}
.p-autocomplete-input {
  background-color: #8de0ff;
  color: black;
  font-family: sans-serif;
  padding-left: 12px;
  border-radius: 1rem;
  outline: none;
}
.p-autocomplete-input::placeholder,
.p-autocomplete-dd-input::placeholder {
  color: black;
  font-family: sans-serif;
  opacity: 1;
}
.customInput.p-inputtext::placeholder {
  color: black;
  font-family: sans-serif;
  opacity: 1;
}
.p-autocomplete-input:focus,
.p-autocomplete-dd-input:focus {
  box-shadow: 0 0 0 0;
  outline: none;
}
.p-dropdown-label {
  color: black;
  max-width: 300px;
  height: 100%;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
.text-red-placeholder .p-placeholder {
  color: red;
}
.text-red .p-confirm-popup-content {
  color: red;
}
.p-autocomplete-dropdown {
  background-color: #8de0ff;
  color: black;
  border-radius: 1rem;
}

.progressBar {
  appearance: none;
  height: 21px;
  width: 100%;
  border-radius: 100px;

  /* Chrome and Safari */
  &::-webkit-progress-bar {
    background: white;
    border-radius: 100px;
  }

  &::-webkit-progress-value {
    background-color: pink;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  /* Firefox */
  &::-moz-progress-bar {
    background-color: pink;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
}

.hide {
  display: none;
}

.hovering:hover + .hide {
  display: block;
  color: white;
}
.p-datepicker-trigger {
  background-color: #8de0ff;
  color: white;
}
.p-confirm-popup-accept {
  background-color: #8de0ff;
}
.p-confirm-popup-reject {
  color: #8de0ff;
}
.text-red .p-confirm-popup-accept {
  background-color: red;
  color: white;
}
.p-confirm-popup-reject {
  color: red;
}
.p-confirm-popup-accept,
.p-confirm-popup-reject {
  margin: 10px;
  padding: 10px;
}
.p-button:focus,
.p-component:focus,
.p-button-text:focus {
  box-shadow: 0 0 0 0;
  outline: none;
}
.height-0 .p-tabview-nav {
  padding: 0;
  margin: 0;
  height: 0px;
  list-style: none;
}
.p-tabview-nav {
  display: flex;
  color: #666666;
}
.p-tabview-selected {
  color: #3fb7eb;
}
.custom-tabview .p-tabview-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.custom-tabview .p-tabview-nav li {
  flex: 1;
  text-align: center;
}
.custom-tabview .p-tabview-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #f4f4f4;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-tabview .p-tabview-nav li {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 5px;
  border-radius: 4px;
}

.custom-tabview .p-tabview-nav li:hover {
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

.custom-tabview .p-tabview-nav li.p-highlight {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
  background-color: #e0e0e0;
}
.p-tabview-nav li {
  flex: 1;
}
.download-link {
  cursor: pointer;
  text-decoration: none;
  color: #007ad9;
}

.download-link:hover {
  text-decoration: underline;
}
.no-download-link {
  cursor: not-allowed;
}

.no-download-link:hover {
  text-decoration: underline;
}
.p-inputnumber-input {
  padding-left: 10px;
}
.p-inputnumber-button-group {
  border-radius: 0.25rem;
  background-color: #8de0ff;
}
.break-word {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.auto-hyphens {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.calendly .p-dialog-header {
  padding-bottom: 0;
}
.calendly {
  overflow-y: hidden;
}
.calendly .p-tabview-panels {
  padding-top: 0;
}
.calendar-input input {
  text-align: center;
}
